import React, {useContext} from 'react';
import {StaticImage} from 'gatsby-plugin-image';

import styled from '@emotion/styled';
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';

import SEO from '../components/common/SEO';
import {breakpoints, colors, ContentContainer} from '../styles/theme';
import AnimatedBackground from '../components/common/AnimatedBackground';
import {Hero, P} from '../components/common/Typography';
import {LinkAsPrimaryButton, LinkAsSecondaryButton} from '../components/common/Clickables';
import {ContrastContext} from '../helpers/context';


/*
 * Private Elements
 */
const FullPageAnimatedBackground = styled(AnimatedBackground)`
    min-height: 100vh;
`;

const PageContentContainer = styled(ContentContainer)`
    min-height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    color: ${colors.white};

    .text-container,
    .image-container {
        max-width: 500px;
    }

    .text-container {
        margin-top: 64px;

        ${P} {
            margin-top: 24px;
        }

        .buttons-container {
            margin-top: 64px;
            margin-bottom: 64px;
        }

        ${LinkAsPrimaryButton} {
            margin-right: 10px;
            margin-bottom: 16px;
        }
    }

    .image-container {
        order: -1;
        margin-top: 124px;
        width: 100%;
    }

    @media (min-width: ${breakpoints.md}) {
        flex-direction: row;
        justify-content: space-evenly;

        padding-left: 25px;
        padding-right: 25px;

        .text-container,
        .image-container {
            max-width: 600px;
        }

        .text-container {
            margin-top: 0;

              .buttons-container {
                    margin-bottom: 0;
              }
        }

        .image-container {
            order: revert;
            margin-top: 0;
        }
    }

    // Hidden visually, but seen by screen readers, etc.
    .visually-hidden {
        position: absolute !important;
        top: -9999px !important;
        left: -9999px !important;
    }
`;


/*
 * Public Elements
 */
const NotFoundPage = () => {
    const setContrast = useContext(ContrastContext);
    setContrast(false);
    const {t} = useTranslation();

    return (
        <>
            <SEO title={t('404: Not found')} />
            <FullPageAnimatedBackground>
                <PageContentContainer>
                    <div className="text-container">
                        <h1 className="visually-hidden"><Trans>404: Not Found</Trans></h1>
                        <Hero as="h2"><Trans>Seems like you&apos;re lost on the abstraction.</Trans></Hero>
                        <P><Trans>We can&apos;t find what  you&apos;re looking for.</Trans></P>
                        <div className="buttons-container">
                            <LinkAsPrimaryButton to="/"><Trans>Go to Homepage</Trans></LinkAsPrimaryButton>
                            <LinkAsSecondaryButton to="/about/contact"><Trans>Contact us</Trans></LinkAsSecondaryButton>
                        </div>
                    </div>
                    <div className="image-container">
                        <StaticImage src="../images/404.png" alt="" />
                    </div>
                </PageContentContainer>
            </FullPageAnimatedBackground>
        </>
    );
};

export default NotFoundPage;
